/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lazysizes@5.1.2/lazysizes.min.js
 * - /npm/lazysizes@5.1.2/plugins/aspectratio/ls.aspectratio.min.js
 * - /npm/lazysizes@5.1.2/plugins/unveilhooks/ls.unveilhooks.min.js
 * - /npm/swiper@8.4.4/swiper-bundle.min.js
 * - /npm/flatpickr@4.6.9/dist/flatpickr.min.js
 * - /npm/flatpickr@4.6.9/dist/l10n/ja.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
